import { ref } from 'vue';

export const isOpen = ref(false);
export const modalTitle = ref('');
export const modalContent = ref('');

export const openModal = (title, content) => {
  modalTitle.value = title;
  modalContent.value = content;
  isOpen.value = true;
};

export const closeModal = () => {
  isOpen.value = false;
};
