<template>
  <div class="message-card-container">
    <MazCard :class="transparent && '--transparent' ">
      <template #title>
        <div class="d-flex flex-column">
          <i class="bi" :class="iconMap[icon]"></i>
          <p v-if="title" class="h3 text-center">{{ title }}</p>
        </div>
      </template>
      <template v-if="message || linkText" #content>
        <div class="text-center">
          <p class="mb-0">{{ message }}</p>
          <MazBtn size="sm" v-if="linkText" :to="link" @click="$emit('clickButton')" block>
            {{ linkText }}
          </MazBtn>
        </div>
      </template>
    </MazCard>
  </div>
</template>

<script setup lang="ts">
import { MazBtn, MazCard } from 'maz-ui/components';
import { TUrlString } from '@/types/types';

const iconMap = {
  success: 'bi-check-circle-fill',
  warning: 'bi-exclamation-circle-fill',
  info: 'bi-info-circle-fill',
  error: 'bi-x-circle-fill',
};

withDefaults(defineProps<{
  title?: string,
  message?: string
  icon?: 'success' | 'warning' | 'info' | 'error'
  link?: TUrlString | string
  linkText?: string
  transparent?: boolean
}>(), {
  icon: 'info',
  transparent: false
});

defineEmits(['clickButton']);
</script>

<style scoped lang="scss">
.message-card-container {
  margin: auto;
  max-width: 300px;
  display: flex;
  justify-content: center;

  :deep(.m-card.--transparent) {
    background-color: transparent !important;
  }
}

.bi {
  font-size: 3.5rem;
  text-align: center;
}

.bi-x-circle-fill {
  color: var(--maz-color-danger);
}

.bi-exclamation-circle-fill {
  color: var(--maz-color-warning);
}

.bi-info-circle-fill {
  color: var(--maz-color-info);
}

.bi-check-circle-fill {
  color: var(--maz-color-success);
}
</style>
