import { CustomerAppliedBonus, CustomerAppliedBonusType } from "@/types/types"
import axios from '@/plugins/axios';
import { APIURL } from '@/utils/url';

type CreateAppliedBonusData = CustomerAppliedBonus

type CreateAppliedBonusReturn = {
  id: number,
  type?: CustomerAppliedBonusType,
  veancoins_amount?: number,
  balance_amount?: number,
  certificate?: string
}

const createAppliedBonus = async (data: CreateAppliedBonusData): Promise<CreateAppliedBonusReturn> => {
  const response = await axios.post<CreateAppliedBonusReturn>(`${APIURL}/v1/customers/applied-bonus/`, data)
  return response.data
}

export {
  createAppliedBonus
}