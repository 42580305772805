import axios from '@/plugins/axios';
import { APIURL } from '@/utils/url';

type CreateModarationCoinsData = {
  file: string[];
  customer: number;
  type: number;
};

const createModarationCoins = async (data: CreateModarationCoinsData): Promise<void> => {
  await axios.post(`${APIURL}/v1/moderation-coin/`, data);
};

export { createModarationCoins };
