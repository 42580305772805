import axios from '@/plugins/axios';
import { APIURL } from '@/utils/url';
import {
  TEmailString,
} from '@/types/types';

type TCreateCertificateData = {
  denomination: number;
  referral_customer: number;
  parlor: number;
  customer: string;
  type: number;
  preferred_sending_email?: TEmailString;
  is_created_by_customer_balance?: boolean;
  is_created_by_customer_vean_coins?: boolean;
  // ...
};

type TCreateCertificateResponse = {
  id: number;
  preferred_sending_email: TEmailString;
  // ...
};

const createCertificate = async (data: TCreateCertificateData): Promise<TCreateCertificateResponse> => {
  const response = await axios.post(`${APIURL}/v1/certificates/`, data);
  return response.data;
};

export {
  createCertificate,
}