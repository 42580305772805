import { openModal } from '@/store/modalState';
import { createRouter, createWebHistory } from 'vue-router';
import i18n from '@/locales/index';
import { crmUserNavigation, customerUserNavigation, redirectNavigation } from '@/router/navigation';

const IndexCRM = () => import('@/views/IndexCRM');
const SelectParlor = () => import('@/views/Selectors/SelectParlor');
const SuccessCRM = () => import('@/views/SuccessCRM');
const AboutUser = () => import('@/views/AboutUser.vue');
const CandidateForm = () => import('@/views/Forms/Candidate/СandidateForm.vue');
const ParlorPage = () => import('@/views/ParlorView/ParlorView.vue');
const MysteryShopper = () => import('@/views/MysteryShopper.vue');
const ClientPage = () => import('@/views/ClientPage/ClientPage.vue');
const MasterTips = () => import('@/views/Master/MasterTips.vue');
const QuestionaryView = () => import('@/views/Questionary/QuestionaryView.vue');
const PostSessionView = () => import('@/views/PostSession/PostSessionView.vue');
const PromotionView = () => import('@/views/Promotion/PromotionView.vue');
const CandidateLayout = () => import('@/layouts/CandidateLayout/CandidateLayout.vue');
const ParlorLayout = () => import('@/layouts/ParlorLayout/ParlorLayout.vue');
const TrainingLayout = () => import('@/layouts/TrainingLayout/TrainingLayout.vue');
const HomePage = () => import('@/views/Training/Home/HomePage.vue');
const FirstQuestion = () => import('@/views/Questionary/Questions/FirstQuestion.vue');
const SecondQuestion = () => import('@/views/Questionary/Questions/SecondQuestion.vue');
const ThirdQuestion = () => import('@/views/Questionary/Questions/ThirdQuestion.vue');
const FourthQuestion = () => import('@/views/Questionary/Questions/FourthQuestion.vue');
const FifthQuestion = () => import('@/views/Questionary/Questions/FifthQuestion.vue');
const SixthQuestion = () => import('@/views/Questionary/Questions/SixthQuestion.vue');
const SeventhQuestion = () => import('@/views/Questionary/Questions/SeventhQuestion.vue');
const SignatureQuestion = () => import('@/views/Questionary/Questions/SignatureQuestion.vue');
const NotFound = () => import('@/views/NotFound.vue');
const ClientPhone = () => import('@/views/Payment/ClientPhone.vue');
const PaymentView = () => import('@/views/Payment/PaymentView.vue');
const SelectSession = () => import('@/views/Payment/SelectSession.vue');
const RegisterPage = () => import('@/views/ClientPage/RegisterPage.vue');
const ClientProfileEdit = () => import('@/views/ClientPage/ClientProfileEdit.vue');
const ClientProfileVerification = () => import('@/views/ClientPage/ClientProfileVerification.vue');
const LoginPage = () => import('@/views/ClientPage/LoginPage.vue');
const ConsultationForm = () => import('@/views/Forms/ConsultationForm.vue');
const MainLayout = () => import('../layouts/MainLayout/MainLayout.vue');
const StudyingButtons = () => import('../views/Forms/Studying/StudyingButtons.vue');
const FeedbackView = () => import('@/components/Feedback/FeedbackView.vue');
const ResetPassword = () => import('@/views/User/ResetPassword.vue');
const LinkTreeView = () => import('@/views/LinkTreeView.vue');

const { t } = i18n.global;

const routes = [
  {
    path: '/',
    component: MainLayout,
    meta: {
      title: t('header.online_appointment'),
    },
    children: [
      {
        path: '',
        name: 'Главная',
        component: IndexCRM,
        beforeEnter: (to, from, next) => {
          next({ name: 'Booking type', query: to.query });
        },
      },
      {
        path: '/link-tree',
        component: LinkTreeView,
      },
      {
        path: 'reset-password/:uid/:token',
        component: ResetPassword,
      },
      {
        path: '/certificate/buy',
        name: 'Certificate buy',
        component: () => import('@/views/Certificate/CertificateBuyView.vue'),
      },
      {
        path: '/upload-file/expenses',
        component: () => import('@/views/FileUpload/ExpenseFileUpload.vue'),
      },
      {
        path: '/upload-file/questionnaire-photo',
        component: () => import('@/views/FileUpload/QuestionnairePhotoUploadView.vue'),
      },
      {
        path: 'student-agreement/:slug/:studentId',
        name: 'Student Agreement',
        component: () => import('@/views/Agreement/StudentAgreementView.vue'),
      },
      {
        path: 'student-and-rent',
        name: 'Studend and rent',
        component: () => import('@/views/StudentRent/StudentRentView.vue'),
      },
      {
        path: 'rent-place',
        component: () => import('@/views/StudentRent/RentView.vue'),
      },
      {
        path: 'student-candidate',
        component: () => import('@/views/StudentRent/StudentCandidateView.vue'),
      },
      {
        path: '/check-in/:sessionId',
        component: () => import('@/views/CheckIn/CheckInView.vue'),
        children: [
          {
            path: 'agreement',
            component: () => import('@/views/CheckIn/AgreementView.vue'),
          },
          {
            path: 'healing-agreement',
            component: () => import('@/views/CheckIn/HealingAgreementView.vue'),
          },
          {
            path: 'waiting',
            component: () => import('@/views/CheckIn/WaitingView.vue'),
          },
          {
            path: 'in-work',
            name: 'Session in work',
            component: () => import('@/views/Session/SessionInWorkView.vue'),
          },
          {
            path: 'rate',
            name: 'Session rate',
            component: () => import('@/views/Session/SessionRateView.vue'),
          },
          {
            path: 'healing-products',
            name: 'Session healing products',
            component: () => import('@/views/Session/SessionHealingProductsView.vue'),
          },
          {
            path: 'tips',
            name: 'Session tips',
            component: () => import('@/views/Session/SessionTipsView.vue'),
          },
          {
            path: 'total',
            name: 'Session total',
            component: () => import('@/views/Session/SessionTotalView.vue'),
          },
          {
            path: 'success',
            name: 'Session success',
            component: () => import('@/views/Session/SessionSuccessView.vue'),
          },
        ],
      },
      {
        path: '/payment',
        name: 'Оплата',
        component: PaymentView,
        meta: {
          title: t('client_page.buy_certificate.payment_title'),
        },
        children: [
          {
            path: '',
            name: 'Телефон',
            component: ClientPhone,
          },
          {
            path: ':b64id/session/',
            name: 'Сеансы',
            component: SelectSession,
          },
        ],
      },
      {
        path: '/parlors',
        component: () => import('@/views/ParlorView/ParlorsView.vue'),
      },
      {
        path: '/works',
        name: 'Works',
        component: () => import('@/views/WorksLineVIew.vue'),
        beforeEnter: (to, from, next) => {
          next({ name: 'Booking type', query: to.query });
        },
      },
      {
        path: '/booking',
        component: () => import('@/views/Booking/BookingView.vue'),
        children: [
          {
            path: '',
            name: 'Booking',
            component: () => import('@/views/Booking/BookingInformationView.vue'),
            meta: {
              noBack: true,
            },
          },
          {
            path: 'type',
            name: 'Booking type',
            component: () => import('@/views/Booking/BookingTypeView.vue'),
          },
          {
            path: 'success',
            name: 'Booking success',
            component: () => import('@/views/Booking/BookingSuccessView.vue'),
            beforeEnter: (to, from, next) => {
              if (from.name === 'Booking' || from.name === 'Master') {
                next();
              } else {
                next({ name: 'Booking type' });
              }
            },
          },
          {
            path: 'success-booking',
            component: () => import('@/views/Booking/BookingTempSuccessView.vue'),
            beforeEnter: (to, from, next) => {
              if (from.name === 'Booking' || from.name === 'Master') {
                next();
              } else {
                next({ name: 'Booking type' });
              }
            },
          },
        ],
      },
      {
        path: 'main-site',
        name: 'Main site',
        component: () => import('@/views/MainSiteView.vue'),
        meta: {
          noBack: true,
        },
      },
      {
        path: 'mystery/:sessionId',
        name: 'Session mystery',
        component: () => import('@/views/Booking/BookingMysteryShopperView.vue'),
      },
      {
        path: '/master/:id',
        name: 'Master',
        component: () => import('@/views/Master/MasterView.vue'),
        meta: {
          title: t('index.master'),
        },
      },
      {
        path: '/session-agreement/:id',
        component: () => import('@/views/Session/AgreementView.vue'),
      },
      {
        path: '/verify/:id',
        name: 'Verify Email',
        component: () => import('@/views/VerifyEmailView.vue'),
      },
      {
        path: '/selector/parlor',
        name: 'Выбор салона',
        component: SelectParlor,
      },
      {
        path: '/consultation',
        name: 'Consultation',
        component: ConsultationForm,
        meta: {
          title: t('header.get_price'),
        },
      },
      {
        path: '/consultation/success',
        name: 'Consultation success',
        component: () => import('@/views/Forms/SuccessView.vue'),
        meta: {
          title: t('header.get_price'),
        },
        beforeEnter: (to, from, next) => {
          if (from.path === '/consultation') {
            next();
          } else {
            next('/');
          }
        },
      },
      {
        path: '/About',
        name: 'Дополнительная информация',
        component: AboutUser,
      },
      {
        path: '/store',
        name: 'Store',
        component: () => import('@/views/Store/ItemsView.vue'),
      },
      {
        path: '/store/:id',
        name: 'Store item',
        component: () => import('@/views/Store/ItemView.vue'),
      },
      {
        path: '/calculate-price',
        name: 'Calculate price',
        component: () => import('@/views/CalculatePriceView.vue'),
      },
      {
        path: '/success',
        name: 'Запись создана',
        component: SuccessCRM,
        beforeEnter: (to, from, next) => {
          if (from.path === '/verify' || from.path === '/About') {
            next();
          } else {
            next('/');
          }
        },
      },
      {
        path: '/mystery-shopper',
        name: 'Тайный покупатель',
        component: MysteryShopper,
      },
      {
        path: '/promotion/:b64formUser',
        name: 'Промоутер',
        component: PromotionView,
      },
      {
        path: '/client/sessions',
        name: 'Client sessions',
        component: () => import('@/views/ClientPage/Session/SessionsView.vue'),
        meta: { requiresAuth: true },
        children: [
          {
            path: ':sessionId',
            component: () => import('@/views/ClientPage/Session/SessionView.vue'),
            children: [
              {
                path: '',
                component: () => import('@/views/ClientPage/Session/SessionDetailView.vue'),
              },
              {
                path: 'credit',
                component: () => import('@/views/ClientPage/Session/SessionCreditView.vue'),
              },
            ],
          },
        ],
      },
      {
        path: '/ai-sketch/',
        name: 'AI sketch',
        component: () => import('@/views/ClientPage/AiGenerationView.vue'),
      },
      {
        path: '/masters',
        component: () => import('@/views/Master/MastersView.vue'),
      },
      {
        path: '/client/:b64id',
        name: 'Client page',
        component: ClientPage,
        meta: { requiresAuth: true },
      },
      {
        path: '/support/',
        component: () => import('@/views/Support/SupportView.vue'),
      },
      {
        path: '/support/complain',
        component: () => import('@/views/Support/ComplainView.vue'),
      },
      {
        path: '/client/cart',
        name: 'Client cart',
        component: () => import('@/views/ClientPage/CartView.vue'),
        meta: { requiresAuth: true },
      },
      {
        path: '/client/registration',
        name: 'Client registration',
        component: RegisterPage,
        meta: {
          title: t('client_page.registration.registered'),
        },
      },
      {
        path: '/client/old-sessions',
        name: 'Client sessions (Old)',
        component: () => import('@/views/ClientPage/SessionsView.vue'),
        meta: { requiresAuth: true },
        beforeEnter: (to, from, next) => {
          if (!to.query.status) {
            next({ ...to, query: { ...to.query, status: 200 } });
          } else {
            next();
          }
        },
      },
      {
        path: '/client/edit/',
        name: 'Редактирование',
        component: ClientProfileEdit,
        meta: { requiresAuth: true, title: t('client_page.profile_card.edit') },
      },
      {
        path: '/client/verification/:b64id?',
        name: 'Верификация',
        component: ClientProfileVerification,
        meta: { requiresAuth: true },
      },
      {
        path: '/master/:id/tips',
        name: 'Чаевые мастеру',
        component: MasterTips,
      },
      {
        path: 'healing/:sessionId',
        name: 'Healing questionary',
        component: () => import('@/views/HealingQuestionaryView/HealingQuestionaryView.vue'),
      },
      {
        path: '/client/login',
        name: 'Login',
        meta: {
          title: t('client_page.registration.log_in'),
        },
        component: LoginPage,
      },
      {
        path: '/questionary/:b64sessionId/',
        name: 'Agreement',
        meta: {
          title: t('client_page.profile_card.agreement'),
          requiresAuth: true,
        },
        component: QuestionaryView,
        children: [
          {
            path: 'first',
            name: 'Agreement - 1',
            component: FirstQuestion,
          },
          {
            path: 'second',
            name: 'Agreement - 2',
            component: SecondQuestion,
          },
          {
            path: 'third',
            name: 'Agreement - 3',
            component: ThirdQuestion,
          },
          {
            path: 'fourth',
            name: 'Agreement - 4',
            component: FourthQuestion,
          },
          {
            path: 'fifth',
            name: 'Agreement - 5',
            component: FifthQuestion,
          },
          {
            path: 'sixth',
            name: 'Agreement - 6',
            component: SixthQuestion,
          },
          {
            path: 'seventh',
            name: 'Agreement - 7',
            component: SeventhQuestion,
          },
          {
            path: 'signature',
            name: 'Подпись',
            component: SignatureQuestion,
          },
        ],
      },
      {
        path: '/studying',
        name: 'Studying',
        component: StudyingButtons,
        children: [
          {
            path: 'registration',
            name: 'Student Registration',
            component: () => import('@/views/Forms/Studying/RegistrationForm.vue'),
          },
          {
            path: 'consultation',
            name: 'Student Consultation',
            component: () => import('@/views/Forms/Studying/ConsultationForm.vue'),
          },
        ],
      },
      {
        path: '/credit',
        name: 'Credit',
        component: () => import('@/views/Credit/CreditBuyView.vue'),
      },
      {
        path: '/removal',
        name: 'Removal',
        component: () => import('@/views/Removal/RemoveView.vue'),
        children: [
          {
            path: 'registration',
            name: 'Remove Tattoo Registration',
            component: () => import('@/components/Forms/RemoveTattooRegistration.vue'),
          },
          {
            path: 'consultation',
            name: 'Remove Tattoo Consultation',
            component: () => import('@/components/Forms/RemoveTattooConsultation.vue'),
          },
        ],
      },
      {
        path: '/feedback',
        name: 'Feedback',
        component: FeedbackView,
        children: [
          {
            path: 'error-ticket',
            name: 'Error Ticket',
            component: () => import('@/components/ErrorTicket.vue'),
          },
          {
            path: 'problem-situation',
            name: 'Problem after the session',
            component: () => import('@/views/Support/ProblemSituationView.vue'),
          },
        ],
      },
      {
        path: '/call-us',
        component: () => import('@/views/CallUsView.vue'),
      },
      {
        path: '/:b64id',
        name: 'Quality check',
        component: PostSessionView,
        meta: { qualityCheck: true, title: t('header.post_session') },
      },
    ],
  },
  {
    path: '/user',
    redirect: '/user/dashboard',
    component: () => import('@/layouts/UserLayout.vue'),
    children: [
      {
        path: 'dashboard',
        component: () => import('@/views/User/Dashboard/DashboardView.vue'),
        name: 'User dashboard',
        meta: {
          userAuthRequired: true,
        },
        children: [
          {
            path: '',
            component: () => import('@/views/User/Dashboard/MainView.vue'),
            meta: {
              title: 'Dashboard',
            },
          },
          {
            path: 'sessions',
            component: () => import('@/views/User/Dashboard/SessionsView.vue'),
            meta: {
              title: 'My sessions',
            },
          },
          {
            path: 'referral-links',
            component: () => import('@/views/User/Dashboard/ReferralLinksView.vue'),
            meta: {
              title: 'My referral links',
            },
          },
        ],
      },
      {
        path: 'profile',
        component: () => import('@/views/User/Profile/ProfileView.vue'),
        meta: {
          userAuthRequired: true,
          title: 'Profile',
        },
        children: [
          {
            path: '',
            component: () => import('@/views/User/Profile/DocumentsView.vue'),
          },
        ],
      },
      {
        path: 'login',
        component: () => import('@/views/User/LoginView.vue'),
        name: 'User login',
        meta: {
          title: 'Login',
        },
      },
    ],
  },
  {
    path: '/new-landing/',
    component: () => import('@/layouts/LandingLayout/NewLandingLayout.vue'),
    children: [
      {
        name: 'Landing',
        component: () => import('@/views/NewLandingPage/LandingPage.vue'),
        path: '',
      },
    ],
  },
  {
    path: '/candidates-form',
    name: 'Candidate form',
    component: CandidateLayout,
    children: [
      {
        name: 'Кандидат',
        component: CandidateForm,
        path: '',
      },
    ],
  },
  {
    path: '/parlor',
    component: ParlorLayout,
    children: [
      {
        path: '',
        name: 'Салон',
        component: ParlorPage,
      },
    ],
  },
  {
    path: '/training',
    component: TrainingLayout,
    children: [
      {
        path: ':b64id?',
        name: 'Home',
        component: HomePage,
      },
    ],
  },
  {
    path: '/:catchAll(.*)',
    name: 'NotFound',
    component: NotFound,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { top: 0 };
    }
  },
});

const getParentTitle = (index, matched) => {
  if (!index) {
    return null;
  } else {
    const { title } = matched[index].meta;
    return title || getParentTitle(index - 1, matched);
  }
};
const setPageTitle = (to) => {
  const defaultTitle = 'VeAn Customers';
  const metaTitle = to.meta?.title || getParentTitle(to.matched.length - 1, to.matched);
  document.title = metaTitle ? `${metaTitle} | ${defaultTitle}` : defaultTitle;
};

router.beforeEach((to, from, next) => {
  const { successPayment, errorPayment } = to.query;
  if (successPayment) {
    console.log(successPayment, 23423423243234);
    openModal('Success', 'Your payment was successful!');
  } else if (errorPayment) {
    openModal('Error', 'There was an issue with your payment.');
  }

  if (customerUserNavigation(to, next)) return;
  if (crmUserNavigation(to, next)) return;
  if (redirectNavigation(to, from, next)) return;

  return next();
});

router.afterEach((to) => {
  setPageTitle(to);
});

export default router;
