import axios from '@/plugins/axios';
import { TCreditService, TEmailString, TPhoneNumberString, TUrlString } from '@/types/types';
import { APIURL } from '@/utils/url';

export type GetCreditServiceReturn = TCreditService[];

const getCreditServices = async (): Promise<GetCreditServiceReturn> => {
  const response = await axios.get(`${APIURL}/v1/credit-service/`);
  return response.data.results;
};

export type CreateCreditFormData = {
  language?: string;
  country?: string;
  parlor?: number;
  phone?: TPhoneNumberString;
  email?: TEmailString;
  social_media?: TUrlString;
  type_education?: number;
  type_service?: number;
  credit_type?: number;
  note?: string;
  full_name?: string;
};

const createCreditForm = async (data: CreateCreditFormData): Promise<void> => {
  await axios.post(`${APIURL}/v1/client-credit-form/`, data);
};

export { getCreditServices, createCreditForm };
