import axios from '@/plugins/axios';
import { APIURL } from '@/utils/url';

type TattooRemovalData = {
  parlor: number;
  email: string;
  first_name: string;
  last_name: string;
  date_of_birth: Date;
  phone_number: string;
  service_type: number;
  service_type_location: number;
  tattoo_photo?: string[];
};

const removeTattoo = async (data: TattooRemovalData): Promise<void> => {
  await axios.post(`${APIURL}/v2/roulettes/tattoo-removal/`, data );
};

export { removeTattoo };
